import {useParams} from "react-router-dom";
import {Box} from "@mui/material";

const Duhi = () => {
    const params = useParams()

    return(
        <Box
            minHeight="100vh"
            width="100vw"
            sx={{
                backgroundImage: `url(${require(`./../images/${params?.id}.jpg`)})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center'
            }}
        >
        </Box>
    )
}


export default Duhi