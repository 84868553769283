import {createTheme} from "@mui/material";


export const mainTheme = createTheme({
    palette: {
        primary: {
            main: "#1A3868",
            light: "#184BA0",
            contrastText: "#ffffff"
        },
        secondary: {
            main: "#2C3E50",
            light: "#BDC3C7",
            contrastText: "#ffffff"
        }
    },
});