import {Box, Grid, ThemeProvider} from "@mui/material";
import SettingsComponent from "./SettingsComponent";
import PreviewComponent from "./PreviewComponent";
import {mainTheme} from "../utils/Theme"
import {useState} from "react";
import MainContext from '../utils/Context'


const MainPage = () => {
    const [values, setValue] = useState({
        roundedDots: false,
        imageSize: 20,
        eyeRadius: 0,
        level: 0,
        foregroundColor: "#000000",
        backgroundColor: "#ffffff"
    })


    return (
        <MainContext.Provider value={[values, setValue]}>
            <ThemeProvider theme={mainTheme}>
                <Box
                    sx={{background: `linear-gradient(180deg, ${mainTheme.palette.secondary.main} 0%, ${mainTheme.palette.secondary.light} 100%);`}}
                    minHeight="100vh"
                    width="100vw"
                >
                    <Box padding={2}>
                        <Grid
                            rowSpacing={0} columnSpacing={2}
                            container
                        >
                            <Grid item xs={12} md={8} xl={8}>
                                <SettingsComponent/>
                            </Grid>
                            <Grid item xs={12} md={12-8} xl={12 - 8}>
                                <PreviewComponent/>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>
        </MainContext.Provider>
    )
}


export default MainPage