import {useContext, useEffect, useRef, useState} from "react";
import MainContext from "../utils/Context";
import {Box, Button, Paper, Stack} from "@mui/material";
import {QRCode} from "react-qrcode-logo";


const PreviewComponent = () => {
    const [values] = useContext(MainContext)
    const [size, setSize] = useState(0)
    const ref = useRef(null)
    const levels = ['L', 'M', 'Q', 'H']

    const handleDownload = () => {
        let canvas = document.getElementById("qrcode")
        let image = canvas.toDataURL("image/png", 1.0).replace("image/png", "image/octet-stream")
        let link = document.createElement('a')
        link.download = "qrcode.png"
        link.target = "_blank"
        link.href = image
        link.click()
        setSize(ref.current ? ref.current.offsetWidth : 0)
    }

    useEffect(()=>{
        window.addEventListener("resize", ()=>{setSize(ref.current ? ref.current.offsetWidth : 0)})
        setSize(ref.current ? ref.current.offsetWidth : 0)
        // eslint-disable-next-line
    },[ref.current])

    return (
        <Paper ref={ref}>
            <Stack padding={2} spacing={2}>
                <QRCode
                    size={size-50}
                    ecLevel={levels[values.level]}
                    value={values.link}
                    logoImage={values.imageURL}
                    logoHeight={size/100*values.imageSize}
                    logoWidth={size/100*values.imageSize}
                    removeQrCodeBehindLogo
                    eyeRadius={values.eyeRadius}
                    fgColor={values.foregroundColor}
                    bgColor={values.backgroundColor}
                    qrStyle={values.roundedDots ? "dots": "squares"}
                />
                <Box hidden>
                    <QRCode
                        id="qrcode"
                        size={1500}
                        ecLevel={levels[values.level]}
                        value={values.link}
                        logoImage={values.imageURL}
                        logoHeight={size/100*values.imageSize}
                        logoWidth={size/100*values.imageSize}
                        removeQrCodeBehindLogo
                        eyeRadius={values.eyeRadius}
                        fgColor={values.foregroundColor}
                        bgColor={values.backgroundColor}
                        qrStyle={values.roundedDots ? "dots": "squares"}
                    />
                </Box>

                <Button onClick={handleDownload} variant="contained" color="success">Скачать</Button>
            </Stack>
        </Paper>
    )
}


export default PreviewComponent