import {
    Box,
    Button,
    Grid,
    Icon,
    Paper,
    Slider,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import MainContext from "../utils/Context";
import {useContext, memo} from "react";


const SettingsComponent = () => {
    const [values, setValue] = useContext(MainContext)

    const handleChange = (e) => {
        if(e.target.name === "roundedDots"){
            setValue({
                ...values,
                roundedDots: e.target.checked
            })
            return
        }
        if (e.target.name === "image" && !!e.target.files[0]?.size) {
            setValue({
                ...values,
                imageURL: URL.createObjectURL(e.target.files[0]),
                image: e.target.value
            })
            return
        }
        setValue({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Paper sx={{padding: 1}}>
            <Grid rowSpacing={0} columnSpacing={2} container>

                <Grid item xs={12} md={12} xl={6}>
                    <Stack padding={2} spacing={2}>
                        <TextField
                            onChange={handleChange}
                            value={values.link || ''}
                            name="link"
                            margin="normal"
                            fullWidth
                            type="url"
                            required
                            label="Ссылка"
                        />

                        <Button color={!values.imageFile?.size ? "inherit" : "success"}
                                component="label"
                                variant="contained"
                                endIcon={<Icon>upload</Icon>}>
                            {" "}
                            Изображение
                            <input onChange={handleChange}
                                   value={values.image || ''}
                                   hidden
                                   name="image"
                                   accept="image/png,image/jpeg"
                                   id="contained-button-file"
                                   type="file"/>
                        </Button>

                        <Box>
                            <Typography gutterBottom>Размер изображения</Typography>
                            <Slider
                                min={10}
                                max={45}
                                step={1}
                                name="imageSize"
                                value={values.imageSize}
                                onChange={(e)=>{handleChange(e)}}/>
                        </Box>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12} xl={6}>
                    <Stack padding={2} spacing={2}>
                        <Box>
                            <Typography gutterBottom>Скругление контрольных точек</Typography>
                            <Slider
                                min={0}
                                max={100}
                                step={5}
                                name="eyeRadius"
                                value={values.eyeRadius}
                                onChange={(e)=>{handleChange(e)}}/>
                        </Box>

                        <Box>
                            <Typography gutterBottom>Детализация</Typography>
                            <Slider
                                min={0}
                                max={3}
                                step={1}
                                name="level"
                                value={values.level}
                                onChange={(e)=>{handleChange(e)}}/>
                        </Box>

                        <Box>
                            <Typography gutterBottom>Скругление точек</Typography>
                            <Switch checked={values.roundedDots} name={"roundedDots"} onChange={handleChange} />
                        </Box>

                        <Box>
                            <Typography gutterBottom>Цвет точек</Typography>
                            <input value={values.foregroundColor} name="foregroundColor" onChange={handleChange} type="color"/>
                        </Box>

                        <Box>
                            <Typography gutterBottom>Цвет фона</Typography>
                            <input value={values.backgroundColor} name="backgroundColor" onChange={handleChange} type="color"/>
                        </Box>

                    </Stack>

                </Grid>
            </Grid>
        </Paper>
    )
}


export default memo(SettingsComponent)