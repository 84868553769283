import MainPage from "./components/MainPage";
import {Route, Routes} from 'react-router-dom'
import Duhi from "./components/Duhi";


function App() {
    return (
        <div className="App">
            <Routes>
                <Route exact path="/" element={<MainPage/>}/>
                <Route path="/1/:id" element={<Duhi/>}/>
            </Routes>
        </div>
    )
}


export default App;
